import React from 'react';
import GLOBAL_STYLES from '../styles';

const Testimonials = props => {
  const {
    windowWidth,
  } = props;
  const noOfCols = windowWidth < 700 ? 1 : 2;
  const bubbleBorderStyle = `1px dotted ${GLOBAL_STYLES.gray}`;

  return (
    <div style={styles.testimonialsContainer}>
      <div style={styles.testimonialsTitlePill}>
        <h3 style={styles.testimonialsTitle}>TESTIMONIALS</h3>
      </div>
      <div
        style={{
          display: 'grid',
          height: '100%',
          gridTemplateColumns: 'repeat(auto-fit, minmax(14em,1fr))',
          gridAutoRows: 'auto',
        }}
      >
        {/* TESTIMONIAL 1 */}
        <div
          style={{
            ...styles.gridCell,
            gridColumn: 1,
            gridRow: 1,
            borderBottom: bubbleBorderStyle,
            borderRight: windowWidth >= 700 ? bubbleBorderStyle : null,
          }}
        >
          <span style={styles.customer}>Greg Wilson</span>
          <div
            className="speech-bubble-left"
            style={styles.bubble}
          >
            Whenever I need a plumber I know who to ask - your service is always 5 star!
          </div>
        </div>
        {/* TESTIMONIAL 2 */}
        <div
          style={{
            ...styles.gridCell,
            gridColumn: noOfCols,
            gridRow: noOfCols === 2 ? 1 : 2,
            borderBottom: bubbleBorderStyle,
          }}
        >
          <div
            className="speech-bubble-right"
            style={styles.bubble}
          >
            Thanks to everyone involved and keep up the great work guys!
          </div>
          <span style={styles.customer}>Jessica York</span>
        </div>
        {/* TESTIMONIAL 3 */}
        <div
          style={{
            ...styles.gridCell,
            gridColumn: 1,
            gridRow: noOfCols === 2 ? 2 : 3,
            borderBottom: windowWidth < 700 ? bubbleBorderStyle : null,
            borderRight: windowWidth >= 700 ? bubbleBorderStyle : null,
          }}
        >
          <span style={styles.customer}>Mrs. Brown</span>
          <div
            className="speech-bubble-left"
            style={styles.bubble}
          >
            Quick and reliable service!
          </div>
        </div>
        {/* TESTIMONIAL 4 */}
        <div
          style={{
            ...styles.gridCell,
            gridColumn: noOfCols,
            gridRow: noOfCols === 2 ? 2 : 4,
          }}
        >
          <div
            className="speech-bubble-right"
            style={styles.bubble}
          >
            Came out to fix the plumbing on bank holiday - life savers!
          </div>
          <span style={styles.customer}>John Richmond</span>
        </div>
      </div>
    </div>
  );
};

const styles = {
  testimonialsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'center',
  },
  testimonialsTitlePill: {
    borderRadius: '200px',
    backgroundColor: GLOBAL_STYLES.gray,
    marginBottom: '15px',
    padding: '7px 17px',
  },
  testimonialsTitle: {
    color: GLOBAL_STYLES.white,
    letterSpacing: '0.2em',
    fontFamily: 'Roboto',
    margin: 0,
  },
  gridCell: {
    padding: '20px 25px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bubble: {
    padding: '10px',
    margin: '0 10px',
    color: GLOBAL_STYLES.white,
    fontSize: '1.1em',
  },
  customer: {
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    color: GLOBAL_STYLES.darkBlue,
    fontWeight: 'bold',
    fontSize: '1.2em',
  }
};

export default Testimonials;
