import React from 'react';
import { Container } from 'react-bootstrap';
import logo from '../assets/logo.png';
import checkATrade from '../assets/checkatrade.png';
import checkATradeHover from '../assets/checkatrade_hover.png'
import About from './About';
import Testimonials from './Testimonials';
import GallerySection from './GallerySection';
import GLOBAL_STYLES from '../styles';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: 1000,
      hoveredElement: null,
    };
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
  }

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  updateWindowWidth = () => this.setState({ windowWidth: window.innerWidth });

  render() {
    const {
      windowWidth,
      hoveredElement,
    } = this.state;

    return (
      <div>
        {/* HEADER BAR */}
        <div style={styles.header}>
          <img src={logo} alt="LOGO" height={100} />
          <h1 style={styles.headerTitle}>NICK ROAKE</h1>
          <h1 style={styles.headerTitle}>PLUMBING AND HEATING</h1>
          <h3 style={styles.headerSubTitle}>EXPERTISE YOU CAN TRUST</h3>
          <h5 style={styles.headerContactText}>
            01483 853 682 &nbsp;&nbsp;|&nbsp;&nbsp;
            07760 888 025 &nbsp;&nbsp;|&nbsp;&nbsp;
            <span
              style={{
                ...styles.headerContactTextLink,
                color: hoveredElement === 'EMAIL' ? GLOBAL_STYLES.red : GLOBAL_STYLES.darkBlue,
              }}
              onClick={() => window.open("mailto:nickroakeplumbing@gmail.com")}
              onMouseOver={() => this.setState({ hoveredElement: 'EMAIL' })}
              onMouseOut={() => this.setState({ hoveredElement: null })}
            >
              NICKROAKEPLUMBING@GMAIL.COM
            </span>
          </h5>
          <div style={styles.headerLinkRow}>
            <div
              style={{
                ...styles.headerLinkCell,
                color: hoveredElement === 'CHECK_A_TRADE' ? GLOBAL_STYLES.red : GLOBAL_STYLES.darkBlue,
              }}
              onClick={() => window.open("https://www.checkatrade.com/trades/NickRoakePlumbingandHeating/", "_blank")}
              onMouseOver={() => this.setState({ hoveredElement: 'CHECK_A_TRADE' })}
              onMouseOut={() => this.setState({ hoveredElement: null })}
            >
              <img src={hoveredElement === 'CHECK_A_TRADE' ? checkATradeHover : checkATrade} alt="CHECK-A-TRADE" style={styles.checkATradeLogo} />
            </div>
            <div
              style={{
                ...styles.headerLinkCell,
                color: hoveredElement === 'FACEBOOK' ? GLOBAL_STYLES.red : GLOBAL_STYLES.darkBlue,
              }}
              onClick={() => window.open("https://www.facebook.com/nickroakeplumbing", "_blank")}
              onMouseOver={() => this.setState({ hoveredElement: 'FACEBOOK' })}
              onMouseOut={() => this.setState({ hoveredElement: null })}
            >
              <i className="fa fa-facebook-official fa-3x"/>
            </div>
            <div
              style={{
                ...styles.headerLinkCell,
                color: hoveredElement === 'INSTAGRAM' ? GLOBAL_STYLES.red : GLOBAL_STYLES.darkBlue,
              }}
              onClick={() => window.open("https://www.instagram.com", "_blank")}
              onMouseOver={() => this.setState({ hoveredElement: 'INSTAGRAM' })}
              onMouseOut={() => this.setState({ hoveredElement: null })}
            >
              <i className="fa fa-instagram fa-3x"/>
            </div>
          </div>
        </div>
        {/* ABOUT SECTION */}
        <Container fluid style={styles.aboutSection}>
          <About windowWidth={windowWidth} />
        </Container>
      {/* TESTIMONIALS SECTION */}
      <Container fluid style={styles.testimonialsSection}>
        <Testimonials windowWidth={windowWidth} />
      </Container>
      {/* GALLERY SECTION */}
      <Container fluid style={styles.gallerySection}>
        <GallerySection />
      </Container>
      {/* FOOTER SECTION */}
        <Container fluid style={styles.footerSection}>
          <h5 style={styles.footText}>NICK ROAKE PLUMBING & HEATING &copy; {(new Date()).getFullYear()}</h5>
        </Container>
      </div>
    );
  }
}

const styles = {
  header: {
    backgroundColor: GLOBAL_STYLES.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  headerTitle: {
    color: GLOBAL_STYLES.gold,
    fontFamily: 'Playfair Display SC',
    fontSize: '5.3vw',
    margin: '10px 0 0 0',
  },
  headerSubTitle: {
    color: GLOBAL_STYLES.gold,
    fontFamily: 'Roboto',
    fontSize: '2.4vw',
    letterSpacing: '0.5em',
    marginTop: '2vw',
    marginBottom: 0,
  },
  headerContactText: {
    color: GLOBAL_STYLES.darkBlue,
    fontFamily: 'Roboto',
    fontSize: '2.2vw',
    letterSpacing: '0.05em',
    marginTop: '2vw',
    marginBottom: '0.5vw',
  },
  headerContactTextLink: {
    cursor: 'pointer',
  },
  headerLinkRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerLinkCell: {
    cursor: 'pointer',
    marginLeft: '2vw',
    marginRight: '2vw',
    marginTop: '1.5vw',
  },
  checkATradeLogo: {
    height: '2.5em',
  },
  aboutSection: {
    backgroundColor: GLOBAL_STYLES.lightBlue,
    padding: '20px',
    borderTop: `1px solid ${GLOBAL_STYLES.darkBlue}`,
  },
  testimonialsSection: {
    backgroundColor: GLOBAL_STYLES.white,
    padding: '20px',
    borderTop: `1px solid ${GLOBAL_STYLES.darkBlue}`,
  },
  gallerySection: {
    backgroundColor: GLOBAL_STYLES.lightBlue,
    padding: '20px',
    borderTop: `1px solid ${GLOBAL_STYLES.darkBlue}`,
  },
  footerSection: {
    backgroundColor: GLOBAL_STYLES.white,
    padding: '10px',
    borderTop: `1px solid ${GLOBAL_STYLES.darkBlue}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footText: {
    fontFamily: 'Roboto',
    fontSize: '1em',
    margin: 0,
    color: GLOBAL_STYLES.gray,
  },
};

export default App;
