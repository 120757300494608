import React from 'react';
import { Col, Row } from 'react-bootstrap';
import gasSafe from '../assets/gasSafe.png';
import GLOBAL_STYLES from '../styles';

const About = props => {
  const {
    windowWidth,
  } = props;
  const noOfCols = windowWidth < 700 ? 1 : windowWidth < 1200 ? 2 : 3;

  return (
    <div
      style={{
        display: 'grid',
        height: '100%',
        gridTemplateColumns: 'repeat(auto-fit, minmax(14em,1fr))',
        gridAutoRows: 'auto',
      }}
    >
      {/* EXPERIENCE CELL */}
      <div
        style={{
          ...styles.gridCell,
          gridColumn: 1,
          gridRow: 1,
        }}
      >
        <Row style={styles.aboutRow}>
          <Col xs={3} style={styles.aboutIconCell}>
            <i className="fa fa-calendar fa-3x"/>
          </Col>
          <Col xs={9} style={styles.aboutTextCell}>
            <h5 style={styles.aboutText}>15 YEARS EXPERIENCE</h5>
          </Col>
        </Row>
      </div>
      {/* CALL-OUT CELL */}
      <div
        style={{
          ...styles.gridCell,
          gridColumn: noOfCols > 1 ? 2 : 1,
          gridRow: noOfCols > 1 ? 1 : 2,
        }}
      >
        <Row style={styles.aboutRow}>
          <Col xs={3} style={styles.aboutIconCell}>
            <i className="fa fa-clock-o fa-3x"/>
          </Col>
          <Col xs={9} style={styles.aboutTextCell}>
            <h5 style={styles.aboutText}>24/7 CALL OUT</h5>
          </Col>
        </Row>
      </div>
      {/* PRICES CELL */}
      <div
        style={{
          ...styles.gridCell,
          gridColumn: noOfCols > 2 ? 3 : 1,
          gridRow: noOfCols > 2 ? 1 : noOfCols > 1 ? 2 : 3,
        }}
      >
        <Row style={styles.aboutRow}>
          <Col xs={3} style={styles.aboutIconCell}>
            <i className="fa fa-gbp fa-3x"/>
          </Col>
          <Col xs={9} style={styles.aboutTextCell}>
            <h5 style={styles.aboutText}>FAIR PRICES</h5>
          </Col>
        </Row>
      </div>
      {/* QUALITY WORK CELL */}
      <div
        style={{
          ...styles.gridCell,
          gridColumn: noOfCols > 2 || noOfCols === 1 ? 1 : 2,
          gridRow: noOfCols > 1 ? 2 : 4,
        }}
      >
        <Row style={styles.aboutRow}>
          <Col xs={3} style={styles.aboutIconCell}>
            <i className="fa fa-wrench fa-3x"/>
          </Col>
          <Col xs={9} style={styles.aboutTextCell}>
            <h5 style={styles.aboutText}>HIGH QUALITY WORK</h5>
          </Col>
        </Row>
      </div>
      {/* FAMILY OWNED CELL */}
      <div
        style={{
          ...styles.gridCell,
          gridColumn: noOfCols > 2 ? 2 : 1,
          gridRow: noOfCols > 2 ? 2 : noOfCols > 1 ? 3 : 5,
        }}
      >
        <Row style={styles.aboutRow}>
          <Col xs={3} style={styles.aboutIconCell}>
            <i className="fa fa-users fa-3x"/>
          </Col>
          <Col xs={9} style={styles.aboutTextCell}>
            <h5 style={styles.aboutText}>FAMILY OWNED & OPERATED</h5>
          </Col>
        </Row>
      </div>
      {/* GAS SAFE CELL */}
      <div
        style={{
          ...styles.gridCell,
          gridColumn: noOfCols,
          gridRow: noOfCols > 2 ? 2 : noOfCols > 1 ? 3 : 6,
        }}
      >
        <Row style={styles.aboutRow}>
          <Col xs={3} style={styles.aboutIconCell}>
            <img src={gasSafe} alt="GAS SAFE" style={styles.gasSafeLogo} />
          </Col>
          <Col xs={9} style={styles.aboutTextCell}>
            <h5 style={styles.aboutText}>REGISTERED 588 157</h5>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const styles = {
  about: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: GLOBAL_STYLES.red,
  },
  aboutContainer: {
    width: '360px',
  },
  aboutIconCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: GLOBAL_STYLES.darkBlue,
    padding: 0,
  },
  aboutTextCell: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
  },
  aboutText: {
    marginBottom: 0,
    color: GLOBAL_STYLES.gray,
  },
  aboutRow: {
    height: '4em',
    borderRadius: '2em',
    backgroundColor: GLOBAL_STYLES.white,
  },
  gasSafeLogo: {
    height: '3em',
  },
  gridCell: {
    padding: '10px 25px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
};

export default About;
