import React from 'react';
import Gallery from 'react-photo-gallery';
import GLOBAL_STYLES from '../styles';
import bathroom01 from '../assets/gallery/bathroom-re-fit_01.png';
import bathroom02 from '../assets/gallery/bathroom-re-fit_02.png';
import bathroom03 from '../assets/gallery/bathroom-re-fit_03.png';
import bathroom04 from '../assets/gallery/bathroom-re-fit_04.png';
import bathroom05 from '../assets/gallery/bathroom-re-fit_05.png';
import boiler01 from '../assets/gallery/boiler-re-fit.png';
import hairSalon01 from '../assets/gallery/hair-salon-installation.png';
import kitchen01 from '../assets/gallery/kitchen-re-fit_01.png';
import kitchen02 from '../assets/gallery/kitchen-re-fit_02.png';
import logCabin01 from '../assets/gallery/log-cabin.png';
import showerEnSuite from '../assets/gallery/shower-en-suite.png';

const photos = [
  {
    src: bathroom01,
    width: 4,
    height: 3
  },
  {
    src: bathroom02,
    width: 3,
    height: 4
  },
  {
    src: bathroom03,
    width: 3,
    height: 4
  },
  {
    src: bathroom04,
    width: 4,
    height: 3
  },
  {
    src: bathroom05,
    width: 3,
    height: 4
  },
  {
    src: boiler01,
    width: 468,
    height: 1000
  },
  {
    src: hairSalon01,
    width: 4,
    height: 3
  },
  {
    src: kitchen01,
    width: 747,
    height: 1000
  },
  {
    src: kitchen02,
    width: 3,
    height: 4
  },
  {
    src: logCabin01,
    width: 3,
    height: 4
  },
  {
    src: showerEnSuite,
    width: 3,
    height: 4
  },
];

const GallerySection = props => {
  return (
    <div style={styles.galleryContainer}>
      <div style={styles.galleryTitlePill}>
        <h3 style={styles.galleryTitle}>GALLERY</h3>
      </div>
      <Gallery photos={photos} margin={3} />
    </div>
  );
};

const styles = {
  galleryContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'center',
  },
  galleryTitlePill: {
    borderRadius: '200px',
    backgroundColor: GLOBAL_STYLES.white,
    marginBottom: '15px',
    padding: '7px 17px',
  },
  galleryTitle: {
    color: GLOBAL_STYLES.darkBlue,
    letterSpacing: '0.2em',
    fontFamily: 'Roboto',
    margin: 0,
  },
};

export default GallerySection;
